import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  mainBlue,
  mainWhite,
  mainBlack,
  mainGreen,
  mainBrown,
  screen,
} from "../components/variables";
import { Link as SpyLink } from "react-scroll";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import gsap from "gsap";
import ContactForm from "../components/contact-form";
import styled from "styled-components";

const Wrapper = styled.div`
  .banner {
    background: ${mainBlue};
    min-height: calc(100vh - 104px);
    @media ${screen.xsmall} {
      min-height: 100vh;
    }

    .inner-wrapper {
      visibility: hidden;
    }

    .heading {
      padding: 180px 0 0 0;
      max-width: 750px;
      @media ${screen.xsmall} {
        padding: 270px 0 0 0;
      }
      @media ${screen.large} {
        padding: 300px 0 0 0;
      }

      @media ${screen.xlarge} {
        padding: 355px 0 0 0;
      }

      h1 {
        font-weight: 700;
        font-size: 1.9rem;
        @media ${screen.xsmall} {
          font-size: 2.2rem;
        }
        @media ${screen.medium} {
          font-size: 2.4rem;
        }

        strong {
          color: ${mainGreen};
        }
      }
    }

    .subheading {
      max-width: 750px;
      padding: 40px 0;
      @media ${screen.xsmall} {
        max-width: 690px;
      }
      @media ${screen.medium} {
        max-width: 750px;
      }

      p {
        font-weight: 500;
        line-height: 1.35;
        font-size: 1.65rem;

        @media ${screen.xsmall} {
          font-size: 2.2rem;
        }
        @media ${screen.medium} {
          font-size: 2.4rem;
        }
      }
    }

    .link {
      color: ${mainGreen};
      font-size: 1.4rem;
      border-bottom: 1px ${mainGreen} solid;
      display: table;
      cursor: pointer;
      @media ${screen.xsmall} {
        font-size: 1.6rem;
        border-bottom: 2px ${mainGreen} solid;
      }
      @media ${screen.medium} {
        font-size: 1.88rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainGreen};
          color: ${mainBlue};
        }
      }
    }
  }

  .services {
    background: ${mainWhite};
    color: ${mainBlack};
    padding: 90px 0;
    @media ${screen.xsmall} {
      padding: 150px 0;
    }

    .description {
      max-width: 970px;

      p {
        font-size: 1.4rem;
        line-height: 1.35;
        @media ${screen.xsmall} {
          font-size: 1.6rem;
        }
        @media ${screen.medium} {
          font-size: 1.88rem;
        }
      }
    }

    .bg {
      &--desktop {
        display: none;
        @media ${screen.small} {
          display: block;
          height: 700px;
          background-size: cover;
          background-image: ${(props) =>
            `url(${props.services_background_image})`};
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: fixed;
          position: relative;
          margin: 200px 48px;
          width: calc(100% - 96px);
        }

        @media ${screen.medium} {
          width: calc(100% - 220px);
          margin: 200px 110px;
        }

        .service {
          position: absolute;
          width: 100%;
          padding: 45px 65px 45px 45px;
          max-width: max-content;
          @media ${screen.small} {
            max-width: calc(210px + 20vw);
          }
          @media ${screen.medium} {
            max-width: max-content;
          }

          &--left {
            background: rgba(255, 231, 210, 0.95);
            bottom: -110px;
            left: 0;
            @media ${screen.small} {
              left: 24px;
            }
            @media ${screen.medium} {
              left: -90px;
            }
          }

          &--right {
            background: rgba(255, 231, 210, 0.95);
            right: 0;
            top: -65px;
            @media ${screen.small} {
              right: 24px;
            }
            @media ${screen.medium} {
              right: 100px;
            }
          }

          .heading {
            font-weight: 700;
            font-size: 1.6rem;
            margin: 0 0 40px 0;
          }

          .service-list {
            &__each {
              font-size: 1.2rem;
              margin: 0 0 26px 0;

              &--last {
                font-weight: 700;
                margin: 40px 0 0 0;

                a {
                  color: ${mainBlack};
                  border-bottom: 1px solid ${mainBlack};
                  margin: 0 0 0 2px;
                  @media ${screen.withCursor} {
                    &:hover {
                      background: ${mainBlack};
                      color: ${mainBrown};
                    }
                  }
                }
              }
            }
          }
        }
      }

      &--mobile {
        display: block;
        @media ${screen.small} {
          display: none;
        }

        .bg-img {
          height: 204px;
          background-size: cover;
          background-image: ${(props) =>
            `url(${props.services_background_image})`};
          background-position: center;
          background-repeat: no-repeat;
          margin: 45px 0;
          @media ${screen.xsmall} {
            margin: 60px 0;
            height: 450px;
          }
        }

        .service {
          background: rgba(255, 231, 210, 0.95);
          padding: 54px 21px;
          @media ${screen.xsmall} {
            width: 50%;
          }
          .heading {
            font-weight: 700;
            font-size: 1.35rem;
            margin: 0 0 30px 0;
          }

          .service-list {
            &__each {
              font-size: 1.1rem;

              margin: 0 0 22px 0;

              &--last {
                font-weight: 700;
                margin: 40px 0 0 0;

                a {
                  color: ${mainBlack};
                  border-bottom: 1px solid ${mainBlack};
                  margin: 0 0 0 2px;

                  @media ${screen.withCursor} {
                    &:hover {
                      background: ${mainBlack};
                      color: ${mainBrown};
                    }
                  }
                }
              }
            }
          }

          &--one {
            margin: 44px 0 0 0;
            @media ${screen.xsmall} {
              margin: 58px 0 0 0;
            }
          }

          &--two {
            margin: 0 0 44px 0;
            @media ${screen.xsmall} {
              margin: 0 0 58px 0;
            }
          }
        }
      }
    }

    .link {
      color: ${mainBlue};
      font-size: 1.4rem;
      border-bottom: 2px ${mainBlue} solid;
      margin: 30px 0 0 0;
      display: table;
      @media ${screen.xsmall} {
        font-size: 1.6rem;
      }
      @media ${screen.medium} {
        font-size: 1.88rem;
      }
      @media ${screen.withCursor} {
        &:hover {
          background: ${mainBlue};
          color: ${mainWhite};
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const {
    form_heading,
    hero_heading,
    hero_subheading,
    hero_cta_label,
    services_background_image,
    services_top_description,
    services_bottom_description,
    sevices_cta_label,
    title_tag,
    meta_description,
  } = data.content.data;

  const {
    service_heading_1,
    service_list_1,
    service_heading_2,
    service_list_2,
  } = data.servicesContent.data;

  useEffect(() => {
    // animate banner

    const banner = gsap.timeline();

    banner
      .from(".banner .inner-wrapper", { autoAlpha: 0 })
      .from(".banner .heading", { opacity: 0, y: 5 })
      .from(".banner .subheading", { opacity: 0, y: 5 })
      .from(".banner .link", { opacity: 0, y: 5 });

    // animate services

    const serviceText1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".description--top",
      },
    });

    serviceText1.from(".services .description--top", { opacity: 0, y: 60 });

    const serviceText2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".description--bot",
      },
    });

    serviceText2.from(".services .description--bot", { opacity: 0, y: 60 });

    const serviceList1 = gsap.timeline({
      scrollTrigger: { trigger: ".bg .service--one" },
    });

    serviceList1.from(".bg .service--one", { opacity: 0, y: 60 });

    const serviceList2 = gsap.timeline({
      scrollTrigger: { trigger: ".bg .service--two" },
    });

    serviceList1.from(".bg .service--two", { opacity: 0, y: 60 });

    const service1 = gsap.timeline({
      scrollTrigger: {
        trigger: "#scroll-service-one",
        start: "top 100%",
        end: "bottom 10%",
        toggleActions: "restart none none reverse",
        scrub: true,
      },
    });

    const service2 = gsap.timeline({
      scrollTrigger: {
        trigger: "#scroll-service-two",
        start: "top 100%",
        end: "bottom 10%",
        toggleActions: "restart none none reverse",
        scrub: true,
      },
    });

    service1.from("#scroll-service-two", { y: 200 });

    service2.from("#scroll-service-one", { y: -200 });
  }, []);
  return (
    <Layout>
      <SEO
        title={title_tag}
        description={meta_description}
        webPageInfo={{
          name: title_tag,
          url: "https://www.fmondaysmarketing.com",
          description: meta_description,
        }}
      />
      <Wrapper
        services_background_image={
          services_background_image.thumbnails.cropped.url
        }
      >
        <header className="banner">
          <div className="inner-wrapper">
            <div
              className="heading"
              dangerouslySetInnerHTML={{ __html: hero_heading.html }}
            />
            <div
              className="subheading"
              dangerouslySetInnerHTML={{ __html: hero_subheading.html }}
            />

            <SpyLink className="link" smooth={true} to="contact-form">
              {hero_cta_label}
            </SpyLink>
          </div>
        </header>

        <section className="services">
          <div className="inner-wrapper">
            <div
              className="description description--top"
              dangerouslySetInnerHTML={{
                __html: services_top_description.html,
              }}
            />
            <div className="bg bg--desktop">
              <div className="service service--left" id="scroll-service-one">
                <h2 className="heading">{service_heading_1}</h2>
                <ul className="service-list">
                  {service_list_1.map((item) => (
                    <li className="service-list__each" key={item.item}>
                      {item.item}
                    </li>
                  ))}

                  <li className="service-list__each service-list__each--last">
                    Curious?{" "}
                    <AniLink
                      cover
                      direction="down"
                      bg="#010101"
                      to="/services/"
                    >
                      Find Out More
                    </AniLink>
                  </li>
                </ul>
              </div>

              <div className="service service--right" id="scroll-service-two">
                <h2 className="heading">{service_heading_2}</h2>
                <ul className="service-list">
                  {service_list_2.map((item) => (
                    <li className="service-list__each" key={item.item}>
                      {item.item}
                    </li>
                  ))}

                  <li className="service-list__each service-list__each--last">
                    Interested?{" "}
                    <AniLink
                      cover
                      direction="down"
                      bg="#010101"
                      to="/services/"
                    >
                      See More
                    </AniLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="bg bg--mobile">
              <div className="service service--one">
                <h2 className="heading">{service_heading_1}</h2>
                <ul className="service-list">
                  {service_list_1.map((item) => (
                    <li className="service-list__each" key={item.item}>
                      {item.item}
                    </li>
                  ))}

                  <li className="service-list__each service-list__each--last">
                    Curious?{" "}
                    <AniLink
                      cover
                      direction="down"
                      bg="#010101"
                      to="/services/"
                      aria-label="Go to services page"
                    >
                      Find Out More
                    </AniLink>
                  </li>
                </ul>
              </div>
              <figure className="bg-img"></figure>
              <div className="service service--two">
                <h2 className="heading">{service_heading_2}</h2>
                <ul className="service-list">
                  {service_list_2.map((item) => (
                    <li className="service-list__each" key={item.item}>
                      {item.item}
                    </li>
                  ))}

                  <li className="service-list__each service-list__each--last">
                    Interested?{" "}
                    <AniLink
                      cover
                      direction="down"
                      bg="#010101"
                      to="/services/"
                      aria-label="Go to services page"
                    >
                      See More
                    </AniLink>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="description description--bot"
              dangerouslySetInnerHTML={{
                __html: services_bottom_description.html,
              }}
            />
            <AniLink
              cover
              direction="up"
              bg="#010101"
              to="/contact/"
              className="link"
            >
              {sevices_cta_label}
            </AniLink>
          </div>
        </section>
        <ContactForm form_heading={form_heading} />
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    content: prismicHomePag {
      data {
        hero_heading {
          html
        }
        hero_subheading {
          html
        }
        hero_cta_label
        services_top_description {
          html
        }
        services_background_image {
          thumbnails {
            cropped {
              url
            }
          }
        }
        services_bottom_description {
          html
        }
        sevices_cta_label
        form_heading
        title_tag
        meta_description
      }
    }
    servicesContent: prismicServicesPage {
      data {
        service_heading_1
        service_list_1 {
          item
        }
        service_heading_2
        service_list_2 {
          item
        }
      }
    }
  }
`;
